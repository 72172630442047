.body {
    background-color: #F5F5F5;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    font-family: "Montserrat", sans-serif;
    /* font-weight: <weight>; */
    font-style: normal;
}

.nav-style ul li {
    display: inline;
    padding-left: 20px;
}

.nav-style ul li a {
    color: white;
    text-decoration: none;
}

.header-sec {
    padding: 27px 0;
}

.nav-style {
    display: table;
    margin: 0 0 0 auto;
}

.menu-icon {
    display: none;
    color: white;
}

.banner-sec {
    position: relative;
    background-image: url(../img/banner-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: -8%;
}

.banner-sec::after {
    width: 100%;
    height: auto;
    background-color: rgb(2 17 17 / 82%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    z-index: 1;
}

.banner-bg {
    background: url(../img/bg.png) no-repeat;
    background-size: 100%;
    width: 100%;
    height: 406px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100px;
    z-index: 9;
}

.banner-content {
    z-index: 10;
    position: relative;
}

.header-after {
    z-index: 10;
    position: relative;
}

.banner-content h1 {
    color: white;
    font-size: 105px;
}

.banner-content span {
    color: #EF5A23;
    text-transform: uppercase;
    font-weight: bold;
}

.header-logo img {
    width: 50%;
    height: auto;
}

.nav-style button {
    color: white;
    padding: 6px 13px;
    border: none !important;
    border-radius: 5px;
}

.orange-btn {
    background-color: #761D46 !important;
    border: none !important;
}

.brown-btn {
    background-color: #EF5A23 !important;
    border: none !important;
}

.birthday-sec {
    padding: 80px 0 80px 0;
    z-index: 15;
    position: relative;
}

.detail-text-card {
    background-color: white;
    box-shadow: 0px 5px 5px 0 #e0e0e0;
    text-align: center;
    padding: 30px;
    border: 1px solid #cfcfcf30;
    border-radius: 8px;
}

.detail-img-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.detail-img-card {
    border-radius: 8px;
}

.detail-icon {
    font-size: 30px;
    color: #761D46;
    margin-bottom: 5px;
}

.detail-text-card h4 {
    color: #761D46;
}

.birthday-text-style h2 {
    font-size: 45px;
    color: #EF5A23;
    line-height: 62px;
    margin: 10px 0 20px 0;
}

.birthday-text-style p {
    font-size: 18px;
    line-height: 30px;
}

.birth-gift {
    padding-right: 50px;
}

.birth-gift p {
    text-align: justify;
}

.margin-top {
    margin-top: -55px;
}

.margin-plustop {
    margin-top: 40px;
}

.align-self {
    align-self: center;
}

/* .margin-lefttop{
    margin-top: 20px;
} */
.birthday-text-style button {
    background-color: #EF5A23;
    color: white;
    padding: 6px 13px;
    border: none !important;
    border-radius: 5px;
    margin-top: 10px;
}

.marrage-gift {
    padding-left: 50px;
    text-align: right;

}

.marry-gifts-sec {
    padding: 80px 0;
}

.death-sec {
    padding: 80px 0;
}

.footer-logo img {
    width: 90%;
    height: auto;
    margin-bottom: 25px;
}

.footer-logo p {
    color: #3a3837bf;
    font-weight: bold;
}

.footer-s h3 {
    color: #EF5A23;
    margin-bottom: 20px;
}

.social-icons ul li {
    display: inline;
    text-decoration: none;
    padding-right: 15px;
    font-size: 20px;
    color: #761D46;
}

.social-icons ul {
    padding: 0;
}

.footer-links ul li {
    list-style-type: none;
    font-weight: bold;
    padding-bottom: 10px;
}

.footer-links ul li a {
    color: #3a3837bf;
    text-decoration: none;
}

.footer-links ul {
    padding: 0;
}

.foot-i {
    font-size: 20px;
    color: #EF5A23;
    padding-right: 10px;
}

.footer-sec {
    padding-top: 80px;
}

.detail-text-card:hover {
    background-color: #761D46;
    color: white;
}

.detail-text-card:hover .detail-icon {
    color: white;
}

.detail-text-card:hover h4 {
    color: white;
}

.responsive-nav {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 10px 0 10px;
    width: 80%;
    text-align: center;
    right: 0;
    top: 60%;
}

.relative {
    position: relative;
}

.responsive-nav ul li {
    padding-bottom: 10px;
    list-style-type: none;
}

.responsive-nav ul li a {
    color: #433d3dc9;
    font-weight: 700;
    text-decoration: none;
}

.responsive-nav ul {
    padding: 0;
}

.detail-text-card p {
    margin-bottom: 0;
}

.footer-s {
    background-color: #f5f5f5;
    padding-top: 40px;
}

.text-center {
    text-align: center;
    padding: 20px 0;
}

.mar-1 {
    margin-top: 20px;
}