@media only screen and (max-width: 1280px) {
    .banner-bg {
        height: 368px;
    }
    .header-sec {
        padding: 22px 0;
    }
    .copy{
        bottom: 67px;
    }
    .login_section{
        width: 90%;
    }
}

@media only screen and (max-width: 1024px) {
    .nav-style {
        display: none;
    }

    .menu-icon {
        display: block;
        margin: 0 0 0 auto;
    }

    .banner-sec {
        margin-top: -17%;
        height: 80vh;
    }

    .banner-bg {
        bottom: -245px;
    }

    .birthday-text-style h2 {
        font-size: 30px;
        line-height: 44px;
        margin: 5px 0 10px 0;
    }

    .birthday-text-style p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
    }

    .detail-icon {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .detail-text-card h4 {
        font-size: 18px;
    }

    .margin-top {
        margin-top: -50px;
    }

    .birth-gift {
        padding-right: 20px;
    }

    .marrage-gift {
        padding-left: 20px;
    }

    .mar-1 {
        margin-top: 15px;
    }

    .footer-sec {
        padding-top: 10px;
    }

    .death-sec {
        padding: 60px 0;
    }
    .detail-img-card img {
        height: 222px;
        object-fit: cover;
    }
    .header-logo img{
        width: 75%;
    }
    .width-mob svg{
        font-size: 40px;
    }
    .banner-content{
        padding-bottom: 0;
    }
    .login_section {
        width: 95%;
    }
    .copy {
        bottom: 35px;
    }

    .res-just{
        justify-content: right;
    }
}

@media only screen and (max-width: 992px) {
/*thulasi*/
.banner-content{
    padding-bottom: 0;
}
.banner-content h1{
    font-size: 90px;
}
.detail-text-card {
    padding: 15px;
}
.header-logo img {
    width: 100%;
}
.width-mob svg{
    font-size: 40px;
}
.bg_ad_2{
    height: 601px;
}
html[data-placement=horizontal] .nav-container .nav-content {
    width: 38% !important;
}
.bg_ad{
    height: 75vh;
}
.res-just{
    justify-content: center;
}
.famroot img {
    height: 385px;
}
.fam .row{
    justify-content: center;
}
.resgap{
    margin-top: 20px;
}
.image-birth img {
    height: 460px;
}
.birth .row{
    justify-content: center;
    align-items: start;
}

/*thulasi*/
}

@media only screen and (max-width: 768px) {
    .banner-sec {
        margin-top: -22%;
        height: 60vh;
    }

    .banner-bg {
        bottom: -275px;
    }

    .birthday-text-style h2 {
        font-size: 22px;
        line-height: 30px;
        margin: 5px 0 5px 0;
    }

    .width-100 {
        width: 100%;
    }

    .birth-gift {
        padding: 0 0 20px 0;
    }

    .margin-top {
        margin-top: 0px;
    }

    .margin-plustop {
        margin-top: 0;
    }

    .marrage-gift {
        padding: 0 0 20px 0;
        text-align: justify;
    }

    .detail-img-card img {
        object-fit: cover;
    }

    .detail-text-card {
        margin: 0 0 15px 0;
    }

    .marry-gifts-sec {
        padding: 0px 0;
    }

    .footer-sec {
        padding-top: 0px;
    }
/*thulasi*/
    .banner-content h1 {
        line-height: 90px;
        font-size: 85px;
    }
    .detail-text-card {
        padding: 20px;
    }
    .banner-content{
        padding-bottom: 0;
    }
    .bg_ad_2{
        height: 445px;
    }
    html[data-placement=horizontal] .nav-container .nav-content {
        width: 37% !important;
    }
    .tree-wid{
        width: 100% !important;
    }
    .bg_ad{
        height: 75vh;
    }
    .famroot img {
        height: 385px;
    }
    .fam .row{
        justify-content: center;
    }
    .resgap{
        margin-top: 20px;
    }
    .image-birth img {
        height: 460px;
    }
    .birth .row{
        justify-content: center;
        align-items: start;
    }
    /*thulasi*/
}


@media only screen and (max-width: 767px){
/*thulasi*/
.banner-bg {
    bottom: -315px;
}
.header-sec {
    padding: 14px 0;
}
.banner-content h1 {
    line-height: 45px;
    font-size: 45px;
}
.width-mob{
    width: 50% !important;
}
.banner-content {
    padding-bottom: 0;
    padding-top: 30px;
}
.footer-logo img {
    width: 50%;
}
.width-mob svg {
    font-size: 22px;
}
.detail-img-card img{
    margin-bottom: 12px;
}
.bg_ad_2{
    height: 465px;
}
.login_section{
    width: 100%;
}
html[data-placement=horizontal][data-dimension=mobile] main, html[data-placement=vertical][data-dimension=mobile] main {
    padding-left: 0 !important;
}
.image{
    margin-bottom: 20px;
}
html[data-placement=horizontal] .nav-container .nav-content{
    width: 65% !important;
}
.bg_ad{
    height: 65vh;
}
.fam {
    padding: 60px 0;
}.famroot img{
    padding-right: 0;
    margin-bottom: 20px;
}
.famroot-content h2 {
    font-size: 32px;
    line-height: 38px;
}
.fam-service{
    margin-bottom: 20px;
}
.birth h2{
    font-size: 32px;
    line-height: 38px;
}
.image-birth{
    margin-bottom: 20px;
}
.birth-cards{
    margin-bottom: 20px;
}
.famgap{
    margin-top: 0;
}
.vacate{
    padding-bottom: 60px;
}
.column-reverse{
    flex-direction: column-reverse;
}


/*thulasi*/
}

@media only screen and (max-width: 425px) {
    /* .banner-sec {
        margin-top: -33%;
        height: 50vh;
    }

    .banner-content h1 {
        font-size: 26px;
    }

    .banner-content span {
        font-weight: 500;
        font-size: 12px;
    }

    .width-50 {
        width: 50%;
    }

    .header-logo img {
        width: 90%;
        height: auto;
    }

    .birthday-sec {
        padding: 30px 0 40px 0;
    }

    .detail-text-card {
        margin-bottom: 20px;
    }

    .margin-top {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .margin-plustop {
        margin-top: 1px;
    }

    .marry-gifts-sec {
        padding: 0px 0;
    }

    .marrage-gift {
        padding-left: 0px;
        text-align: left;
        margin-top: 15px;
    }

    .death-sec {
        padding: 40px 0;
    }

    .footer-sec {
        padding-top: 30px;
    } */
}